import { navigate } from "gatsby"
import React from "react"

// THIS PAGE IS JUST TO REDIRECT TO ADMIN DASHBOARD /account-settings ROOT (so, currently we want "wesparkle.co/account-settings" -> "wesparkle.ai/account-settings")

const AccountSettings = () => {
  if (typeof window !== "undefined") {
    console.log("window.location.replace")
    window.location.replace(`${process.env.GATSBY_SPARKLE_ADMIN_PATH}`)
  }
  return null
}

export default AccountSettings
